import * as React from "react"
import Layout from "../components/layout/layout"
import Hero from "../components/hero/hero"
import Seo from "../components/seo/seo"
import {graphql, HeadProps, PageProps} from "gatsby"
import "../styles/global.scss"
import {paragraphResolver} from '../helpers/paragraphsResolver'
import {
  DrupalNode,
  DrupalFormattedText,
  DrupalMediaImage,
  DrupalParagraph
} from "../types/drupal"

interface NodeHomePageProps extends DrupalNode {
  field_hero_text: DrupalFormattedText
  field_intro_text: DrupalFormattedText
  field_intro_headline: string
  relationships?: {
    field_background_image?: DrupalMediaImage
    field_components?: DrupalParagraph[]
  }
}

interface DataProps {
  nodeHomepage: NodeHomePageProps
}

const IndexPage = ({data: {nodeHomepage}}: PageProps<DataProps>) => {
  const {
    field_hero_text,
    relationships,
    field_intro_headline,
    field_intro_text,
  } = nodeHomepage
  const media = relationships?.field_background_image
  const introText = field_intro_text?.value
  const heroText = field_hero_text?.value
  const components = relationships?.field_components

  return (
    <Layout>
      <Hero
        title={heroText}
        introHeading={field_intro_headline}
        introText={introText}
        variant="homepage"
        level={1}
        media={media}
      />
      {components && components.map(component => {
        return (
          <React.Fragment key={component.id}>
            {paragraphResolver({ node: component })}
          </React.Fragment>
        )
      })}
    </Layout>
  )
}

export const query = graphql`
  query HomePage {
    nodeHomepage {
      id
      title
      field_intro_text {
        processed
        value
      }
      field_intro_headline
      field_hero_text {
        processed
        value
      }
      relationships {
        field_background_image {
          field_image {
            alt
          }
          relationships {
            field_image {
              publicUrl
              gatsbyImage(width: 500, layout: FIXED, quality: 100)
            }
          }
        }
        field_components {
          __typename
          ...ParagraphSideBySide
          ...ParagraphText
          ...ParagraphIconSpacer
          ...ParagraphLogoGroup
          ...ParagraphImage
          ...ParagraphCardList
          ...ParagraphCardListStacked
          ...Paragraph3ColumnText
        }
      }
    }
  }
`

export default IndexPage

export const Head = ({data: {nodeHomepage}}: HeadProps<DataProps>) => {
  return (
    <>
      <html lang={nodeHomepage.langcode}/>
      {nodeHomepage?.metatag ? <Seo metatag={nodeHomepage.metatag}/> : <title>{nodeHomepage.title}</title>}
    </>
  )
}
